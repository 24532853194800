@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  padding: 0;
  margin: 0;
  height: 100%;
  @apply bg-white dark:bg-dark-blue;
  @apply text-gray-900 dark:text-white;
  @apply font-body;
  @apply transition duration-500
}

* {
  box-sizing: border-box;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* .Toastify__toast--success {
  @apply !bg-green-600
}

.Toastify__toast--error {
  @apply !bg-red-500
}

.Toastify__toast--warning {
  @apply !bg-yellow-500
} */

.splide__pagination__page.is-active{
  @apply !bg-main-blue
}

.splide__pagination__page {
  @apply !mx-1
}

.premium-benefits .splide__arrow.splide__arrow--prev {
  left: -1em !important;
}

.premium-benefits .splide__arrow.splide__arrow--next {
  right: -1em !important;
}

